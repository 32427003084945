<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">공고 현황</h2>
      </div>
      <div class="body_section">
        <AnnounceInfo :itemData="itemData" />
      </div>
    </div>
    <Sticky>
      <div class="area_left">
        <button type="button" class="btn_fourthly btn_large" @click="onClickGoToList">목록</button>
      </div>
      <div class="area_right">
        <!-- 입찰 전: 입찰, 임시저장 상태: 일찰 수정, 입찰 완료 상태: 입찰 내역 -->
        <button v-if="canGoBid" type="button" class="btn_primary btn_large" @click="onClickBid">
          {{ isBidFinished ? "입찰 참여 내역" : "입찰" }}
        </button>
      </div>
    </Sticky>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import AnnounceInfo from "@/components/user/shared/AnnounceInfo";
import Sticky from "@/components/layout/content/Sticky";
import PageMixin from "@/mixins/PageMixin";
import ApiService from "@/services/ApiService";

import { PROGRESS_START_ACTION, PROGRESS_END_ACTION } from "@/store/modules/progressbar/action";

export default {
  name: "AnnounceView",
  components: {
    PageWithLayout,
    AnnounceInfo,
    Sticky,
  },
  mixins: [PageMixin],
  data() {
    return {
      itemData: {},

      postCid: "",
      bidStatus: null,

      postStatus: null,
    };
  },
  computed: {
    isBidFinished() {
      return this.bidStatus === "F";
    },
    canGoBid() {
      if (!this.postStatus || !this.bidStatus) return false;

      // 공고 입찰 진행 중이 아니면 무조건 불가능
      if (this.postStatus !== "S") return false;

      // 입찰 마감이면 무조건 불가능. 그 외에는 무조건 가능.
      if (this.bidStatus === "F") return false;

      return true;
    },
  },
  beforeMount() {
    this.postCid = this.$route.params.id;

    // 입찰 api에 공고 정보도 포함되어 있어서, 공고 상세 api 호출 안하는 걸로 변경.
    // const path = `${ this.$apiPath.ANNOUNCE }/${ this.postCid }`
    // this.getData( path );

    this.$store.dispatch(PROGRESS_START_ACTION);

    // 입찰 정보
    this.getBidData(this.postCid);
  },
  methods: {
    // async getData( path ) {
    //   const result = await ApiService.shared.getData( path );
    //   if( !result.data )  return;

    //   this.itemData = result.data;

    //   this.postStatus = this.itemData.status;
    // },
    async getBidData(postCid) {
      const path = `${this.$apiPath.BID}/${postCid}`;
      const result = await ApiService.shared.getData(path);

      this.$store.dispatch(PROGRESS_END_ACTION);

      if (!result.data) return;

      const { postBoard, postTender } = result.data;

      // 입찰 api에 공고 정보도 포함되어 있어서, 공고 상세 api 호출 안하는 걸로 변경.
      this.itemData = postBoard;
      this.postStatus = this.itemData.status;

      if (!postTender) return;

      this.bidStatus = postTender.status;
    },
    onClickGoToList() {
      this.$router.push({
        path: this.$routerPath.ANNOUNCE_LIST,
        query: this.$route.query,
      });
    },
    onClickBid() {
      const toRouterPath = this.isBidFinished
        ? this.$routerPath.BID_VIEW
        : this.$routerPath.BID_WRITE;
      const path = `${toRouterPath}/${this.postCid}`;

      this.$router.push({
        path: path,
      });
    },
  },
};
</script>
